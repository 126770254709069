.rules {
  &.container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: fixed;
      font-size: 14px;
      margin-top: 400px;
      width: 80%;
      padding: 1em;
      left: 50.4%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      background-color: var(--normalTextColor);
      color:var(--buttonTextColor);
      border-radius: 10px;
      box-shadow: 0px 0px 5px var(--accent);
  }
  .text {
      text-align: left;
  }
  h3 {
      margin-top: -0.5em;
  }
  h1 {
      text-align: center;
  }
  .button {
      &:hover {
          transform: translateY(-2px);
        }
        padding: 6px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        height: 30px;
        width: 100px;
        border: none;
        border-radius: var(--borderRadius);
        cursor: pointer;
        opacity: 1;
        background-color: var(--accent);
        transition: all 0.3s ease;
        color: var(--buttonTextColor);
  }
}