@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .game will compile to
  .game.user-list

  for more information visit https://sass-lang.com/guide
 */

.createMeme {
  &.container {
    margin-top: 3vh;

    /* we include the center mixin defined in _theme.scss and save three duplicate lines :) */
    @include center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 940px) {
      transform: scale(0.4);
      transform-origin: top center;
      @media (orientation: landscape) {
        transform: scale(0.5); // Adjust this to the desired scale for landscape mode
      }
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    min-width: 60em;
    max-width: 80%;
    font-size: 16px;
    font-weight: 300;
    padding-left: 37px;
    padding-right: 37px;
    padding-bottom: 1em;
    border-radius: 0.75em;
    background-color: var(--background);
    transition: opacity 0.5s ease, transform 0.5s ease;
    box-shadow: var(--dropShadow);
    position: relative;
  }
  
  .logo_small{
    max-width: 100%;
  }
  .logo_small:hover {
    transform: translateY(-2px);
  }
  .button_small.left{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    left: 0.5em;
    top: 1em;
  }
  .logo_small_middle {
    user-select: none;
    margin: auto;
    margin-bottom: 2em;
    display: block;
    justify-content: center;
    max-width: 10em;
    margin-top:1em;
    width: 50%;
  }
  .button_small.right{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    right: 0.5em;
    top:1em;
  }
  &.field {
    display: flex;
    width: 60%;
    font-size: 18px;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0em;
  }
  &.input {
    height: 2.5em;
    padding-left: 15px;
    margin-left: -4px;
    font-size: 1.5em;
    border: none;
    border-radius: 0.75em;
    margin-bottom: 20px;
    background: transparentize(rgb(246, 246, 246), 0.85);
    color: var(--normalTextColor);
  }
  &.input::placeholder {
    color: var(--normalTextColor);
  }

  .memeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 1em;
  }

  .roundCounter {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 0em;
  }
  .timer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    }

  h1 {
    text-align: center;
  }
  .timerValue{
    font-size: 40px;
    font-weight: 600;
  }
  .meme{
    width: 170em;
  }
  .meme img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1em;
  }
  .button-container {
    box-sizing: border-box;
    width: 1em;
    overflow: hidden; 
    padding-left: 1em;
  }
}