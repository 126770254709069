@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .game will compile to
  .game.user-list

  for more information visit https://sass-lang.com/guide
 */

.lobby {
  &.container {
    margin-top: 3vh;
    max-height: 100%;
    max-width: 100%;
    /* we include the center mixin defined in _theme.scss and save three duplicate lines :) */
    @include center;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    max-width: 80%;
    font-size: 16px;
    font-weight: 300;
    padding: 1em;
    border-radius: 0.75em;
    background-color: var(--background);
    transition: opacity 0.5s ease, transform 0.5s ease;
    box-shadow: var(--dropShadow);
    position: relative;
  }
  
  .logo_small{
    max-width: 100%;
  }
  .logo_small:hover {
    transform: translateY(-2px);
  }
  .button_small.left{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    left: 0.5em;
    top: 1em;
  }
  .logo_small_middle {
    user-select: none;
    margin: auto;
    margin-bottom: 2em;
    display: block;
    justify-content: center;
    max-width: 10em;
    width: 50%;
  }
  .button_small.right{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    right: 0.5em;
    top:1em;
  }

  .title {
    font-size: 3em;
    font-weight: 900;
    margin-top: 0em;
    margin-bottom: 0.5em;
  }

  .infoContainer {
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: -1em;
    margin-bottom: 4em;
    width: 20em;
    min-width: 50%;
    max-width: 20em;
    padding: 1em;
  }

  .infoLobbyCode{
    margin-bottom: 14em;
    font-weight: bolder;
  }
  .infoContent {
    text-align: right;
  }
  .copy-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-left: 0.5em;
    width: 1em;
    height: 1em;
    img {
      width: 100%;
      height: auto;
    }
  
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  
    &:active {
      transform: scale(0.9);
      transition: transform 0.2s ease-in-out;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Adjust this value to your liking */
    max-width: 100%;
  }
  .button{
    width: 10em;
  }
  .users-container{
    max-width: 100%;
    margin-bottom: 2em;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
    .user-profile {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      flex-direction: column;

      .user-profile-name {
        font-size: 1em;
        display: flex;
        justify-content: center;
      }

      .refresh-button {
        position: absolute;
        bottom: 35%;
        width: 1.5em;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #ddd8d8;
        opacity: 0.8;
        padding: 0;
        cursor: pointer;
        border-radius: 50%;
        img {
          width: 70%;
          height: auto;
        }
      }
    
      .user-profile-picture {
        width: 4em;
        height: 4em;
        border-radius: 50%;
        display: flex;
        justify-self: center;
        margin-right: 0.5em;
        margin-left: 0.5em;
      }
    }

    .rulediv {
      @media (max-width: 940px) {
        font-size: x-small;
        top: 30%
      }
    }
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5em; 
    border: 0.5px solid var(--accent);
    background-color: var(--accent);
    color: var(--buttonTextColor);
    border-radius: 10em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0.8em;
    min-width: 4em;
  }

}