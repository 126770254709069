.popUp {
    &.container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        margin-top: 19em;
        width: 70%;
        padding: 2em;
        left: 50.4%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background-color: var(--normalTextColor);
        color:var(--buttonTextColor);
        border-radius: 10px;
        box-shadow: 0px 0px 5px var(--accent);
    }
    h1 {
        text-align: center;
    }
    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .button {
        &:hover {
            transform: translateY(-2px);
          }
          padding: 6px;
          margin: 10px;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
          text-align: center;
          height: 50px;
          width: 20vw;
          border: none;
          border-radius: var(--borderRadius);
          cursor: pointer;
          opacity: 1;
          background-color: var(--accent);
          transition: all 0.3s ease;
          color: var(--buttonTextColor);
    }
}