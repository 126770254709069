/*
  This file contains the most basic variables that control how your application will look.
 */

/* Theme Colors

   these values control the colors of your application.
   feel free to play around with these and see what other look you can come up with! :D
*/



// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 300 to 700

:root {
  /* Theme Colors */
  --accent: #575757;
  --accentDark: #000000;
  --buttonTextColor: #ffffff;
  --normalTextColor: #1d1d1d;
  --background: #c3c3c3;
  --background_blur: rgba(214, 214, 214, 0.5);

  /*some constants*/
  --DESKTOP_WIDTH: 1160px;
  --SMALL_LAPTOPS_WIDTH: 970px;
  --TABLETS_WIDTH: 750px;
  --SMALL_WIDTH: 768px;


  /* some standard elements */
  /* fonts */
  --fontDefault: Jura, "M PLUS Rounded 1c", sans-serif;
  --fontMono: Jura, "Roboto Mono", monospace;

  /* a standard drop shadow */
  --dropShadow: 1em 1em 1em rgba(0, 0, 0, 0.25);

  --borderRadius: 1em;
}

/* mixins

  these allow you to create small snippets that you can insert with a one-liner in other stylesheets
  for an example, see styles/views/Login.scss
*/
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}