@import "../theme";

.primary-button {
  &:hover {
    transform: translateY(-2px);
  }
  &:disabled {
    cursor: default;
    opacity: 0.4;
    &:hover {
      transform: none;
    }
  }
  padding: 6px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  font-size: 14px;
  text-align: center;
  height: 70px;
  border: none;
  border-radius: var(--borderRadius);
  cursor: pointer;
  opacity: 1;
  background-color: var(--accent);
  transition: all 0.3s ease;
  color: var(--buttonTextColor);
}