@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto+Mono:wght@300;400;500;700&display=swap");
@import "./theme";

/**
This file contains the general and shared style of your application.
 */
body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  font-family: var(--fontDefault);
  background-size: cover;
  user-select: text;
  background-color: #ffffff;
}
.background_img {
  position: fixed;
  user-select: none;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  background-image: url('../components/img/background/background_white.png');
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}
.background_img::after {
  content: "";
  position: fixed;
  user-select: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background_blur); /* Semi-transparent gray */
  z-index: 0;
}

input,
textarea,
select,
button {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  outline: none;
}

*:focus {
  outline: none;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
