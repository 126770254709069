@import "../theme";

/* The '&' is a shorthand for the containing element,
  so for example a '&.user-list' inside .game will compile to
  .game.user-list

  for more information visit https://sass-lang.com/guide
 */

.scoreboard {
  &.container {
    margin-top: 3vh;
    min-height: 300px;
    /* we include the center mixin defined in _theme.scss and save three duplicate lines :) */
    @include center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 940px) {
      transform: scale(0.4);
      transform-origin: top center;
      @media (orientation: landscape) {
        transform: scale(0.5); // Adjust this to the desired scale for landscape mode
      }
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    min-width: 60em;
    max-width: 80%;
    font-size: 16px;
    font-weight: 300;
    padding-left: 37px;
    padding-right: 37px;
    padding-bottom: 1em;
    border-radius: 0.75em;
    background-color: var(--background);
    transition: opacity 0.5s ease, transform 0.5s ease;
    box-shadow: var(--dropShadow);
    position: relative;
  }
  
  .logo_small{
    max-width: 100%;
  }
  .logo_small:hover {
    transform: translateY(-2px);
  }
  .button_small.left{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    left: 0.5em;
    top: 1em;
  }
  .logo_small_middle {
    user-select: none;
    margin: auto;
    margin-bottom: 2em;
    display: block;
    justify-content: center;
    margin-top:1em;
    max-width: 10em;
    width: 50%;
  }
  .button_small.right{
    max-width: 4em;
    max-height: 4em;
    width: 20%;
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    justify-self: end;
    padding: 0;
    margin:0;
    position: absolute;
    display: inline-block;
    right: 0.5em;
    top:1em;
  }

  .rankingContainer {
    display: flex;
    align-items:flex-start;
    width: 100%;
    padding-bottom: 1em;
  }
  .timerTitle {
    text-align: center;
  }
  .title {
    font-size: 4em;
    font-weight: 900;
    margin-top: 0em;
    margin-bottom: 0.5em;
  }
  .timer-wrapper{
    margin-bottom: 15%;
  }
  .timerValue{
    font-size: 40px;
    font-weight: 600;
  }
  .ranking{
    display: block;
    align-items: center;
    margin-left: 2em;
  }
  .rankedUsers {
    display: flex;
    justify-content: space-between;
    border: 4px solid;
    border-color: var(--accent);
    background-color: var(--accent);
    color: var(--buttonTextColor);
    font-weight: 900;
    font-size: larger;
    width: 30em;
    border-radius: 0.75em;
    margin: 10px;
    padding: 10px;

    .user-info {
      display: flex;
      align-items:center;
    }

    .user-profile-picture {
      width: 3em;
      height: 3em;
      border-radius: 50%;
      display: flex;
      justify-self: center;
      margin-right: 0.5em;
      margin-left: 0.5em;
    }

    .user-name {
      display: flex;
      align-items: center;
    }

    .user-score {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      border-color: gold;
      color: gold;
    }
    &:nth-child(2) {
      border-color: silver;
      color: silver;
    }
    &:nth-child(3) {
      border-color: #CD7F32;
      color: #CD7F32;
    }
  }
}